<template>
    <div>
        <PendingHistory
            id="pendingHistory"
            @on-sort-change="onSortChange"
            v-allow:auth="'dashboard.read'"
            :columns="fetchColumns"
            :rows="rows"
            :loadingStates="loadingStates"
            @onPerPageChange="onPageChange($event, true)"
            @onPageChange="onPageChange($event)"
            :paginationOptions="paginationOptions"
            :totalRows="pageData?.total_count"
            :filterOptions="filterOptions"
            :filterOptionsAsync="filterOptionsAsync.value"
            @onColumnFilters="onColumnFilters($event)"
            @getSearchOptionsTick="getSearchOptionsTick($event)"
            @clearFilters="clearFilters"
            :columnFilters="columnFilters"
            @on-allocation="onAllocation($event)"
            :key="currentPageData.page + pageData.total_count ? pageData.total_count : 1"
            :due_date="due_date"
        />
        <AllocationList :isLoading="loadingStates.getAllocation" :allocatedResources="allocatedResources" @removeAllocation="removeAllocation($event)" />
    </div>
</template>

<script>
import PendingHistory from "@shared/components/pending-activity";
import axios from "@/axios";
import { uniqBy } from "lodash";
import { uuid } from "vue-uuid";
import { DateTime } from "luxon";
import { checkPermission } from "@shared/utils/functions";
import { FormatDateNew } from "@/plugins/functions";
const AllocationList = () => import("@/pages/dashboard/pages/components/allocation-list.vue");

export default {
    name: "activity-history-notification",
    title() {
        return `Notifications`;
    },
    data() {
        return {
            rows: [],
            loadingStates: {
                activity: false,
                getAllocation: false,
            },
            paginationOptions: {
                enabled: true,
                mode: "remote",
                perPage: 10
            },
            pageData: null,
            searchTimeout: null,
            currentPageData: {
                page: 1,
                count: 10,
            },
            searchLoading: {
                value: {},
            },
            filterOptionsAsync: {
                value: null,
            },
            columnFilters: null,
            tenantId: null,
            sortParams: null,
            allocatedResources: [],
            allTeams: [],
            allusers: [],
            due_date:""
        };
    },
    components: {
        PendingHistory,
        AllocationList,
    },
    computed: {
        filterOptions() {
            return this.getFilterOptions(this.fetchColumns, this.rows);
        },
        fetchColumns() {
            return this.columns.map((el) => ({
                ...el,
                config: {
                    ...el.config,
                    isLoading: !!this.searchLoading.value[el.field],
                },
            }));
        },
        columns() {
            return [
                {
                    label: "Case ID",
                    field: "visible_case_id",
                    sortable: true,
                    query_key: "visible_case_id",
                    config: {
                        filter: true,
                        type: "link",
                        sortable_key: "case_id",
                    },
                },
                {
                    label: "Subject First Name",
                    field: "candidate_first_name.label",
                    query_key: "candidate_first_name",
                    sortable: true,
                    config: {
                        filter: true,
                        sortable_key: "first_name",
                    },
                },
                {
                    label: "Subject Last Name",
                    field: "candidate_last_name.label",
                    query_key: "candidate_last_name",
                    sortable: true,
                    config: {
                        filter: true,
                        sortable_key: "last_name",
                    },
                },
                {
                    label: "Activity Type",
                    field: "activity_type.label",
                    query_key: "activity_type",
                    sortable: false,
                    config: {
                        filter: true,
                    },
                },
                {
                    label: "Status",
                    field: "status.label",
                    query_key: "status",
                    sortable: false,
                    config: {
                        filter: true,
                    },
                },
                {
                    label: "Due Date",
                    field: "due_date.label",
                    query_key: "due_date.label",
                    sortable: true,
                    field_type: "date",
                    config: {
                        filter: true,
                        filter_type: "date",
                        sortable_key: "due_date",
                        query_keys: {
                            start: "due_date_start_date",
                            end: "due_date_end_date",
                        },
                    },
                },
                {
                    label: "Allocated Group",
                    field: "teams",
                    query_key: "teams",
                    sortable: true,
                    config: {
                        type: "team_allocation",
                        units: "groups",
                        isAllowed: this.checkPermission("case.allocate"),
                        sortable_key: 'groups',
                        // filterOptions: {
                        //   enabled: false, // enable filter for this column
                        // },
                    },
                    prefetchedOptions: this.allTeams?.map((team) => ({ ...team, name: team.title })),
                },
                {
                    label: "Allocated Users",
                    field: "users",
                    query_key: "users",
                    sortable: true,
                    config: {
                        type: "user_allocation",
                        units: "users",
                        isAllowed: this.checkPermission("case.allocate"),
                        sortable_key: 'users',
                        // filterOptions: {
                        //   enabled: false, // enable filter for this column
                        // },
                    },
                    prefetchedOptions: this.allUsers?.map((user) => ({ ...user, name: user.user_name })),
                },
                {
                    label: "Assignee Role",
                    field: "assignee_role.label",
                    query_key: "assignee_role",
                    sortable: false,
                    config: {
                        filter: true,
                    },
                },
                {
                    label: "Assignee Name",
                    field: "assignee",
                    query_key: "assignee",
                    sortable: true,
                    config: {
                        filter: true,
                        sortable_key: "assignee_name",
                    },
                },
                {
                    label: "Check",
                    field: "check",
                    query_key: "check",
                    sortable: false,
                    config: {
                        filter: true,
                    },
                },
                {
                    label: "Title",
                    field: "title.label",
                    query_key: "title",
                    sortable: true,
                    config: {
                        filter: true,
                        type: "rich_text",
                        sortable_key: "title",
                    },
                    tdClass: "truncate max-w-sm",
                },
            ];
        },
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenantId = this.$store.getters.getTenantId;
        this.loadingStates.activity = true;
        await this.fetchAllUsers();
        await this.fetchAllTeams();
        await this.fetchPendingHistory();
    },
    methods: {
        FormatDateNew,
        checkPermission,
        async clearFilters() {
            this.columnFilters = null;
            this.currentPageData = {
                page: 1,
                count: this.currentPageData.count ? this.currentPageData.count : 10,
            };
            await this.fetchPendingHistory(this.currentPageData.page, this.currentPageData.count);
        },
        async fetchPendingHistory(page, count, new_payload) {
            this.loadingStates.activity = true;
            let currentTotalCount = count ? count : this.currentPageData.count
            try {
                const formatDate = (date) => (date ? DateTime.fromISO(date).toFormat("yyyy-LL-dd") : "");
                let acknowledged = new_payload?.status ? new_payload.status[0].value !== "Unacknowledged" : null 
                let payload = {
                    tenant_id: this.tenantId,
                    activity_type: Array.isArray(new_payload?.activity_type) ? new_payload?.activity_type?.map((el) => el?.value) || [] : new_payload?.activity_type?.value || null,
                    assignee_role: Array.isArray(new_payload?.assignee_role) ? new_payload?.assignee_role?.map((el) => el?.value) || [] : new_payload?.assignee_role?.value || null,
                    assignee: Array.isArray(new_payload?.assignee) ? new_payload?.assignee?.map((el) => el?.value) || [] : new_payload?.assignee?.value || null,
                    acknowledged,
                    candidate_first_name: Array.isArray(new_payload?.candidate_first_name) ? new_payload?.candidate_first_name?.map((el) => el?.value) || [] : new_payload?.candidate_first_name?.value || null,
                    candidate_last_name: Array.isArray(new_payload?.candidate_last_name) ? new_payload?.candidate_last_name?.map((el) => el?.value) || [] : new_payload?.candidate_last_name?.value || null,
                    visible_case_id: Array.isArray(new_payload?.visible_case_id) ? new_payload?.visible_case_id?.map((el) => el?.value) || [] : new_payload?.visible_case_id?.value || null,
                    due_date_start_date: new_payload?.due_date_start_date?.name ? formatDate(new_payload?.due_date_start_date?.name) : null,
                    due_date_end_date: new_payload?.due_date_end_date?.name ? formatDate(new_payload?.due_date_end_date?.name) : null,
                    check: Array.isArray(new_payload?.check) ? new_payload?.check?.map((el) => el?.value) || [] : new_payload?.check?.value || null,
                    title: Array.isArray(new_payload?.title) ? new_payload?.title?.map((el) => el?.value) || [] : new_payload?.title?.value || null,
                    page: page || 1,
                    count: currentTotalCount || 10,
                    users: Array.isArray(new_payload?.users) ? new_payload?.users?.map((el) => el?.id) || []: new_payload?.users?.value || null,
                    groups: Array.isArray(new_payload?.teams) ? new_payload?.teams?.map((el) => el?.id) || []: new_payload?.teams?.value || null,
                    ...this.sortParams,
                };
                const { data } = await axios.post(`dashboard-notifications/tenant`, payload);
                this.rows = data?.data?.data.map((el) => ({ ...el, work_allocation: el.case_allocation }));
                const FormateDateRow = (date) => (date ? DateTime.fromISO(date).setZone('UTC').toFormat("dd MMM yyyy") : "");
                this.rows = this.rows.map((ele) => ({ ...ele, due_date: { value: FormateDateRow(ele.due_date.value), label: FormateDateRow(ele.due_date.label) } }));
                this.pageData = data?.data?.page;
                this.due_date = payload.due_date_start_date ? this.FormatDateNew(new Date(payload.due_date_start_date))+ '-' +this.FormatDateNew(new Date(payload.due_date_end_date)) : "Select date";
            } catch (error) {
                console.log(">>>> error", error);
            }
            this.loadingStates.activity = false;
        },
        async onPageChange(data, resetPage = false) {
            if (resetPage) {
                // reset page to one before changing perpage
                this.currentPageData.page = 1;
            }
            let { currentPerPage, currentPage } = data;
            this.paginationOptions.perPage = currentPerPage
            this.currentPageData = {
                page: currentPage,
                count: currentPerPage,
            };

            await this.fetchPendingHistory(currentPage, currentPerPage, this.columnFilters);

            //scroll into top of table when page changes
            if(!resetPage) {
                await this.$nextTick(); // Wait for the DOM to update
                const scrollTarget = document.getElementById('pendingHistory');
                if (scrollTarget) {
                  scrollTarget.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
            }
        },
        async onColumnFilters(data) {
            // reset page to one before applying filters
            this.currentPageData.page = 1;
            Object.keys(data.columnFilters).map((key) => {
                if (!Array.isArray(data.columnFilters[key]) && data.columnFilters[key]?.field_type !== "date" && data.columnFilters[key]?.field_type !== 'status') data.columnFilters[key] = [data.columnFilters[key]];
            });
            this.columnFilters = data?.columnFilters ? { ...data?.columnFilters } : null;
            await this.fetchPendingHistory(this.currentPageData.page, this.currentPageData.count, this.columnFilters);
        },
        getSearchOptionsTick(payload) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(payload);
            }, delay);
        },

        async getSearchOptions(payload) {
            // if searching is canceled reset async filter options for the field:
            if (!payload.value) this.$set(this.filterOptionsAsync, "value", null);
            else {
                // set 'loading' prop for the column we are searching in:
                this.$set(this.searchLoading, "value", true);

                try {
                    const formatDate = (date) => DateTime.fromISO(date).toFormat("yyyy-LL-dd");
                    let filters = {};
                    if (this.columnFilters) {
                        Object.entries(this.columnFilters).forEach(([key, value]) => {
                            let obj = {};
                            obj[key] = Array.isArray(value) ? value.map((filter) => filter.value) : value.value;
                            filters = { ...filters, ...obj };
                        });
                    }
                    const requestData = {
                        ...filters,
                        [payload.column.query_key]: payload.column.field_type === "date" ? formatDate(payload.value) : payload.value,
                    };
                    const { data } = await axios.post(`dashboard-notifications/tenant`, requestData);
                    // set async options:
                    this.$set(this.filterOptionsAsync, "value", this.getFilterOptions(this.fetchColumns, data.data.data));
                } catch (error) {
                    console.log("error :>> ", error);
                }

                this.$set(this.searchLoading, "value", false);
            }
        },
        getFilterOptions(columns, rows) {
            const removeHTML = (str) => {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = str;
                return tmp.textContent || tmp.innerText || "";
            };
            return columns.reduce((result, curr) => {
                const fields = curr.field.split(".");
                result[curr.field] = rows
                    .filter((row) => {
                        return fields?.length === 1 ? row[curr.field] : row[fields[0]][fields[1]];
                    })
                    .map((row) => {
                        let obj = { id: uuid.v4(), name: fields?.length === 1 ? row[curr.field] : row[fields[0]][fields[1]], value: row[fields[0]]?.value };
                        if (!obj.value) {
                            obj.value = obj.name;
                        }
                        return obj;
                    });
                result[curr.field] = uniqBy(result[curr.field], "name");
                if (result["title.label"]) result["title.label"] = result["title.label"].map((el) => ({ ...el, name: removeHTML(el.name) }));
                return result;
            }, {});
        },
        async onSortChange(data) {
            if (data && data.length && data[0].type !== "none") {
                data = data[0];
                (this.sortParams = {
                    order_key: this.checkSortableKey(data.field),
                    order_type: data.type,
                }),
                    await this.fetchPendingHistory();
            } else {
                this.sortParams = null;
                await this.fetchPendingHistory();
            }
        },
        checkSortableKey(field) {
            return this.columns.find((el) => el.field === field && el.sortable).config.sortable_key;
        },
        async onAllocation({ row, type }) {
            console.log(row, type, "adasdsd");
            this.allocatedResources = [];
            this.$modal.show("allocation-list", { type, row });
            await this.fetchAllocationList(row.case_id, type);
        },
        async fetchAllocationList(caseId, type) {
            this.loadingStates.getAllocation = true;
            const url = `/work-allocation/case/${caseId}/${type}`;
            try {
                const { data } = await axios.get(url);
                this.allocatedResources = data?.filter((res) => res.allotted) ?? [];
            } catch (error) {
                console.log(error, "[+] error while fetch allocation list");
            }
            this.loadingStates.getAllocation = false;
        },
        async removeAllocation({ resource, type, row }) {
            this.loadingStates.getAllocation = true;
            let url = "";
            let payload = {
                case_ids: [{ id: row.case_id }],
            };
            if (type === "groups") {
                url = "/work-allocation/case/tenant-group";
                payload.add_groups = [];
                payload.remove_groups = [resource.id];
            } else {
                url = "/work-allocation/case/tenant-user";
                payload.add_users = [];
                payload.remove_users = [resource.id];
            }
            try {
                await axios.post(url, payload);
                await this.fetchAllocationList(row.case_id, type);
                await this.fetchPendingHistory();
                this.$toast.success("Removed Successfully!");
            } catch (error) {
                this.$toast.error("Removing Failed!");
            }
            this.loadingStates.getAllocation = false;
        },
        async fetchAllTeams() {
            this.loadingStates.teams = true;
            try {
                const { data } = await axios.get(`/group/tenant/${this.tenantId}`);
                this.allTeams = data.map((team) => ({ ...team, status: null }));
            } catch (error) {
                console.log(error, "[+] error while fetching teams");
            }
            this.loadingStates.teams = false;
        },
        async fetchAllUsers() {
            this.loadingStates.users = true;
            try {
                const { data } = await axios.get(`/user/tenant/${this.tenantId}`);
                this.allUsers = data.map((user) => ({ ...user, status: null }));
            } catch (error) {
                console.log(error, "[+] error while fetching users");
            }
            this.loadingStates.users = false;
        },
    },
};
</script>

<style></style>
